import React, { useEffect, useRef } from "react";
import { styled } from "../stitches.config";

const ResizableContainer = ({ content }: { content: any }) => {
    const container = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (container && container.current) {
            const parent = container.current?.parentElement;
            const height = container.current?.offsetHeight;
            const parentHeight = (parent ? parent.offsetHeight : 100000);
            // console.log('ResizableContainer', height, parentHeight, parent);

            if (height > parentHeight) {
                container.current?.classList.add('too-much-text');
            }
        }
    }, []);


    return <ResizableContainerWrapper ref={container}>{content}</ResizableContainerWrapper>;
}

const ResizableContainerWrapper = styled("span", {
    letterSpacing: '-0.6px',
    '&.too-much-text': {
        letterSpacing: '-0.8px',
        hyphens: 'auto'
    }
});

export default ResizableContainer;
